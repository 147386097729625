import React, { Component } from "react";
import DiagnosisNotePage from "../pages/DiagnosisNotePage";
import { Redirect } from "react-router-dom";
import Logout from "../pages/Authentication/Logout";
import UserProfile from "../pages/Profile/UserProfile";
import ArticleList from "../pages/Article/ArticleList";
import ArticleDetails from "../pages/Article/ArticleDetails";
import ArticleGrid from "../pages/Article/ArticleGrid";
import CreateArticle from "../pages/Article/CreateArticle/CreateArticle";
import { RouteName } from "../RouteName";
import ProfileDetails from "../pages/Profile/ProfileDetails";
import ChatMainScreen from "../pages/Chat/ChatMainScreen";
import ManageTherapists from "../pages/SuperAdmin/ManageTherapists";
import ManageClients from "../pages/SuperAdmin/ManageClients";
import AppointmentCalendar from "../pages/Appointments/AppointmentCalendar";
import OnboardingPage from "../pages/Profile/Onboarding";
import AssesmentOnboardingPage from "../pages/Assesment/AssesmentOnboardingPage";
import TherapyPlanPage from "../pages/TherapyPlan/TherapyPlanPage";
import AllNotifications from "../components/CommonForBoth/AllNotifications";
import ViewEthnicity from "../pages/SuperAdmin/ViewEthnicity";
import CreateEthnicity from "../pages/SuperAdmin/CreateEthnicity";
import ViewExperienceTag from "../pages/SuperAdmin/ViewExperianceTags";
import CreateExpereinceTag from "../pages/SuperAdmin/CreateExperienceTag";
import ViewProfessions from "../pages/SuperAdmin/ViewProfessions";
import CreateProfession from "../pages/SuperAdmin/CreateProfession";
import ViewProfessionLicense from "../pages/SuperAdmin/ViewProfessionLicenses";
import CreateProfessionLicense from "../pages/SuperAdmin/CreateProfessionLicense";
import ViewHashTags from "../pages/SuperAdmin/ViewHashTags";
import CreateHashTags from "../pages/SuperAdmin/CreateHashTags";
import ViewThemeImages from "../pages/SuperAdmin/ViewThemeImages";
import CreateThemeImage from "../pages/SuperAdmin/CreateThemeImage";
import ViewAllClients from "../pages/SuperAdmin/ViewClients";
import ViewAllTherapists from "../pages/SuperAdmin/ViewAllTherapists";
import ClientSubscription from "../pages/Subscription/ClientSubscription";
import TherapistEarnings from "../pages/Subscription/TherapistEarnings";
import { ReportReviews } from "../pages/SuperAdmin/ReportReviews";
import ViewUserProfile from "../pages/SuperAdmin/ViewUserProfile";
import ViewContactUs from "../pages/SuperAdmin/ViewContactUs";
import TechTickets from "../pages/SuperAdmin/TechTickets";
import ViewEducationalDetails from "../pages/SuperAdmin/ViewEducationalDetails";
import ViewLicenseDetails from "../pages/SuperAdmin/ViewLisenceDetails";
import ViewArticles from "../pages/SuperAdmin/ViewArticles";
import ViewArticleDetails from "../pages/SuperAdmin/ViewArticleDetails";
import Support from "../pages/Support/Support";
import ViewFeedback from "../pages/SuperAdmin/ViewFeedback";
import AllAppointments from "../pages/Appointments/AllAppointments";
import AdminDashboard from "../pages/SuperAdmin/AdminDashboard";
import NewAdminDashboard from "../pages/SuperAdmin/NewAdminDashboard";
import ViewInsurance from "../pages/SuperAdmin/ViewInsurace";
import ManagePremiumClients from "../pages/SuperAdmin/ManagePremiumClients";
import ManageInvoices from "../pages/SuperAdmin/ManageInvoices";
import PaymentHistory from "../pages/Subscription/PaymentHistory";
import AddInsuranceDetails from "../pages/Insurances/InsuranceDetails";
import ClientProfileDetails from "../pages/Profile/ClientProfileDetails";
import MarketingEmail from "../pages/SuperAdmin/MarketingEmail";
import DocumentLayout from "../pages/Documents/DocumentLayout";
import ViewInsuranceCompanies from "../pages/SuperAdmin/ViewInsuranceCompanies";
import CreateInsuranceCompany from "../pages/SuperAdmin/CreateInsuranceCompany";
import ClientSessionsView from "../pages/SuperAdmin/ClientSessionsView";
import TherapistSessions from "../pages/SuperAdmin/TherapistSessions";
import AddAppointmentsAdimn from "../pages/SuperAdmin/AddAppointments";
import DocumentLayoutAdmin from "./../pages/SuperAdmin/Documents/DocumentLayoutAdmin";
import ViewMeetingsAndRecordings from "../pages/SuperAdmin/ViewMeetingsAndRecording";
import MeetingScreenNew from "../pages/Chat/VideoCallNew/components/VideoCallScreenNew";
import ViewAvailableBalances from "src/pages/SuperAdmin/ViewAvailableBalances";
import AdminApprovePayment from "src/pages/SuperAdmin/AdminApprovePayment";
import ReferralEarnings from "src/pages/SuperAdmin/ReferralEarnings";
import ClientRewards from "src/pages/SuperAdmin/ClientRewards";
import ViewMarketingEmails from "src/pages/SuperAdmin/ViewMarketingEmails";
import ZoomWeb from "src/pages/Chat/ZoomNew/ZoomWeb";
import Main from "src/common/main/Main";
import LavniAvatar from "src/pages/Avatar/LavniAvatar";
import PublicInsuranceInfo from "src/common/main/PublicInsuranceInfo";
import CardInfo from "src/common/main/cardInfo";
import CardPayment from "src/common/main/cardPayment";
import AdminNewDashboard from "src/pages/SuperAdmin/AdminNewDashborad";
import ViewUserPublicProfile from "src/pages/SuperAdmin/ViewUserPublicProfile";
import PublicArticalIndex from "src/pages/Article/ArticleGrid/PublicArticalIndex";
import ViewTherapistReview from "src/pages/SuperAdmin/viewTherapistReviews";
import NewletterEmail from "src/pages/SuperAdmin/NewsLetterEmails";
import ViewTherapistsSOAPReview from "src/pages/SuperAdmin/viewTherapistSOAPReviews";
import ClientDashboardNew from "src/pages/Dashboard/ClientDashboardNew";
import TherapistDashboardNew from "src/pages/Dashboard/TherapistDashboardNew";
import TherapistReferral from "src/pages/Referral/therapist-referral";
import ClientReferral from "src/pages/Referral/client-referral";
import clientVerify from "src/common/client-verify/VerifyClient";
import AdInsuranceByAdmin from "src/pages/SuperAdmin/AddInsurace ";
import VideoRoom from "src/pages/Chat/vonage/vonage-express/components/VideoRoom";
import WaitingRoom from "src/pages/Chat/vonage/vonage-express/components/WaitingRoom";
import ViewMeetingsAndRecordingsDev from "src/pages/SuperAdmin/ViewMeetingsAndRecordingDev";
import EndCall from "src/pages/Chat/vonage/vonage-express/components/EndCall";
import GroupChatChatMainScreen from "src/pages/Chat/GroupChat/GroupChatMainScreen";
import WaitingRoomGroup from "src/pages/Chat/vonage/vonage-express-group/components/WaitingRoom";
import VideoRoomGroup from "src/pages/Chat/vonage/vonage-express-group/components/VideoRoom";
import EndCallGroup from "src/pages/Chat/vonage/vonage-express-group/components/EndCall";
import PublicGroupChat from "src/pages/Chat/PublicGroupChat/PublicGroupChat";
import ClientSignaturePage from "src/pages/Assesment/clientSignaturePage";
import WelcomePage from "src/common/main/WelcomePage";
import EditTherapistScoreConstants from "src/pages/SuperAdmin/EditTherapistScoreHeights";
import ViewTherapistScore from "src/pages/SuperAdmin/ViewTherapistScore";
import ViewLavniReviews from "src/pages/SuperAdmin/ViewLavniReviews";
import ViewSessionFeedback from "src/pages/SuperAdmin/viewSessionFeedback";
import AddAppoinmentAdmin from "./../pages/SuperAdmin/AddAppointment/AddAppoinmentAdmin";

import PublicArticles from "src/pages/Article/ArticleList/PublicAricles";
import Tech from "src/pages/Tech/Tech";
import ViewAdminProfile from "src/pages/SuperAdmin/ViewAdminProfile";
import TherapistRequests from "src/pages/SuperAdmin/TherapistRequests";
import ApprovalQueue from "src/pages/SuperAdmin/ApprovalQueue";

import TrainingDocuments from "./../pages/TrainingDocuments/TrainingDocuments";

import FolderComponent from "./../pages/TrainingDocuments/FolderComponent";

import TherapistDocuments from "./../pages/TrainingDocuments/index";
import DocumentAccessManagement from "./../pages/SuperAdmin/Documents/DocumentAccess";
import TherapistPersonalInformationOnboardingPage from "src/pages/Profile/TherapistOnboarding/TherapistPersonalInformationOnboardingPage";
import TherapistProfessionalExperienceOnboardingPage from "src/pages/Profile/TherapistOnboarding/TherapistProfessionalExperienceOnboardingPage";
import TherapistWorkingDaysOnboardingPage from "src/pages/Profile/TherapistOnboarding/TherapistWorkingDaysOnboardingPage";
import TherapistIntroductoryVideoOnboardingPage from "src/pages/Profile/TherapistOnboarding/TherapistIntroductoryVideoOnboardingPage";
import Approval from "src/pages/Approvals/Approval";
import ClientTherapyPlanSignaturePage from "src/pages/TherapyPlan/ClientTherapyPlanSignaturePage";
import TomorrowSchedulePageForTherapist from "src/pages/visitPages/TomorrowSchedulePageForTherapist";
import DocDownload from "src/pages/SuperAdmin/DocDownload";
import ShowTherapistScoreFullDetailsPage from "src/pages/Dashboard/ShowTherapistScoreFullDetailsPage";
import DigitalClientAssessmentSignaturePage from './../pages/NewAssessmentDigitalForm/DigitalClientSignaturePage';
import AllClients from "src/pages/TPDashboard/AllClients";
import ClientsChart from "src/pages/TPDashboard/ClientsChart";
import ToDoSaas from "../pages/TPDashboard/ToDoSaas";
import ClaimsSaas from "src/pages/TPDashboard/ClaimsSaas";
import CredentiallingSaas from "src/pages/TPDashboard/CredentiallingSaas";
import PaymentSaas from "src/pages/TPDashboard/PaymentSaas";
import AddStates from "src/pages/SuperAdmin/AddStates";

const clientRoutes = [
  {
    path: RouteName.ROOT,
    exact: true,
    component: () => <Redirect to={RouteName.DASHBOARD} />,
  },
  { path: RouteName.DASHBOARD, component: ClientDashboardNew },
  { path: RouteName.PROFILE, component: UserProfile },
  { path: RouteName.PROFILE_COPAYMENT, component: UserProfile },
  { path: RouteName.USER_PROFILE, component: ProfileDetails },
  { path: RouteName.CHAT, component: ChatMainScreen },
  { path: RouteName.ARTICLE_LIST, component: ArticleList },
  { path: RouteName.ARTICLE_DETAILS, component: ArticleDetails },
  { path: RouteName.ARTICLE_DETAIL, component: ArticleDetails },
  { path: RouteName.ARTICLES_GRID, component: ArticleGrid },
  { path: RouteName.CREATE_APPOINTMET, component: AppointmentCalendar },
  { path: RouteName.ONBOARDING, component: OnboardingPage },
  { path: RouteName.NOTIFICATIONS, component: AllNotifications },
  { path: RouteName.SUBSCRPTION, component: ClientSubscription },
  { path: RouteName.CHOOSE_PLAN, component: CardInfo },
  { path: RouteName.ADD_PAYMENT, component: CardPayment },
  { path: RouteName.SUPPORT, component: Support },
  { path: RouteName.APPOINTMENTS, component: AllAppointments },
  { path: RouteName.INSURANCE, component: AddInsuranceDetails },
  { path: RouteName.ADD_INSURANCE, component: PublicInsuranceInfo },
  { path: RouteName.VIEW_PAYMENT_HISTORY, component: PaymentHistory },
  { path: RouteName.PAYMENT_HISTORY_CARD, component: PaymentHistory },
  { path: RouteName.DOCUMENTS, component: DocumentLayout },
  { path: RouteName.MEETING_SCREEN_NEW, component: MeetingScreenNew },
  { path: RouteName.LAVNI_SESSION_ROOM, component: ZoomWeb },
  { path: RouteName.LAVNI_AVATAR, component: LavniAvatar },
  { path: RouteName.DASHBOARD_NEW, component: ClientDashboardNew },
  { path: RouteName.VONAGE_EXPRESS, component: WaitingRoom },
  { path: RouteName.VONAGE_VIDEO_ROOM, component: VideoRoom },
  { path: RouteName.VONAGE_END_CALL, component: EndCall },
  { path: RouteName.GROUP_CHAT, component: GroupChatChatMainScreen },
  { path: RouteName.VONAGE_EXPRESS_GROUP, component: WaitingRoomGroup },
  { path: RouteName.VONAGE_VIDEO_ROOM_GROUP, component: VideoRoomGroup },
  { path: RouteName.VONAGE_END_CALL_GROUP, component: EndCallGroup },
  { path: RouteName.PUBLIC_GROUP_CHAT, component: PublicGroupChat },
  { path: RouteName.TECH, component: Tech },
  { path: RouteName.WELCOME, component: WelcomePage },
  { path: RouteName.CLIENT_REFERRAL, component: ClientReferral },
];

const therapistRoutes = [
  {
    path: RouteName.ROOT,
    exact: true,
    component: () => <Redirect to={RouteName.DASHBOARD} />,
  },
  { path: RouteName.DASHBOARD, component: TherapistDashboardNew },
  { path: RouteName.CHAT, component: ChatMainScreen },
  { path: RouteName.PROFILE, component: UserProfile },
  { path: RouteName.DIAGNOSIS_NOTE_SCREEN, component: DiagnosisNotePage },
  { path: RouteName.ARTICLE_LIST, component: ArticleList },
  { path: RouteName.ARTICLE_DETAILS, component: ArticleDetails },
  { path: RouteName.ARTICLE_DETAIL, component: ArticleDetails },
  { path: RouteName.CREATE_ARTICLE, component: CreateArticle },
  { path: RouteName.ARTICLES_GRID, component: ArticleGrid },
  { path: RouteName.USER_PROFILE, component: ProfileDetails },
  // { path: RouteName.ONBOARDING, component: OnboardingPage },
  { path: RouteName.NOTIFICATIONS, component: AllNotifications },
  { path: RouteName.EARNINGS, component: TherapistEarnings },
  { path: RouteName.SUPPORT, component: Support },
  { path: RouteName.APPOINTMENTS, component: AllAppointments },
  { path: RouteName.CLIENT_PROFILE, component: ClientProfileDetails },
  // { path: RouteName.DOCUMENTS, component: DocumentLayout },
  { path: RouteName.DOCUMENTS, component: TherapistDocuments },
  { path: RouteName.MEETING_SCREEN_NEW, component: MeetingScreenNew },
  { path: RouteName.LAVNI_SESSION_ROOM, component: ZoomWeb },
  { path: RouteName.DASHBOARD_NEW2, component: TherapistDashboardNew },
  { path: RouteName.THERAPIST_REFERRAL, component: TherapistReferral },
  { path: RouteName.VONAGE_EXPRESS, component: WaitingRoom },
  { path: RouteName.VONAGE_VIDEO_ROOM, component: VideoRoom },
  { path: RouteName.VONAGE_END_CALL, component: EndCall },
  { path: RouteName.GROUP_CHAT, component: GroupChatChatMainScreen },
  { path: RouteName.VONAGE_EXPRESS_GROUP, component: WaitingRoomGroup },
  { path: RouteName.VONAGE_VIDEO_ROOM_GROUP, component: VideoRoomGroup },
  { path: RouteName.VONAGE_END_CALL_GROUP, component: EndCallGroup },
  { path: RouteName.TECH, component: Tech },
  // { path: RouteName.THERAPIST_TRAINING_DOCS, component: TherapistDocuments },
  { path: RouteName.THERAPIST_DOC_FOLDER, component: FolderComponent },
  { path: RouteName.THERAPISTPERSONALINFORMATIONONBOARDINGPAGE, component: TherapistPersonalInformationOnboardingPage },
  { path: RouteName.THERAPISTPROFESSIONALEXPERIENCEONBOARDINGPAGE, component: TherapistProfessionalExperienceOnboardingPage },
  { path: RouteName.THERAPISTWORKINGDAYSONBOARDINGPAGE, component: TherapistWorkingDaysOnboardingPage },
  { path: RouteName.THERAPISTINTRODUCTORYVIDEOONBOARDINGPAGE, component: TherapistIntroductoryVideoOnboardingPage },
  { path: RouteName.APPROVALS, component: Approval },
  { path: RouteName.SHOW_THERAPIST_SCORE, component: ShowTherapistScoreFullDetailsPage}
];

const adminRoutes = [
  {
    path: RouteName.ROOT,
    exact: true,
    component: () => <Redirect to={RouteName.NEWDASHBOARD} />,
  },
  { path: RouteName.ADMIN_DASHBOARD, component: AdminDashboard },
  { path: RouteName.NEW_ADMIN_DASHBOARD, component: NewAdminDashboard },
  { path: RouteName.MANAGE_CLIENTS, component: ManageClients },
  { path: RouteName.MANAGE_THERAPISTS, component: ManageTherapists },
  { path: RouteName.VIEW_ETHNICITY, component: ViewEthnicity },
  { path: RouteName.CREATE_ETHNICITY, component: CreateEthnicity },
  { path: RouteName.VIEW_EXPERIENCE_TAGS, component: ViewExperienceTag },
  { path: RouteName.CREATE_EXPERIENCE_TAGS, component: CreateExpereinceTag },
  { path: RouteName.VIEW_PROFESSIONS, component: ViewProfessions },
  { path: RouteName.CREATE_PROFESSION, component: CreateProfession },
  { path: RouteName.UPDATE_PROFESSION, component: CreateProfession },
  { path: RouteName.VIEW_PROFESSION_LICENSE, component: ViewProfessionLicense },
  { path: RouteName.CREATE_PROFESSION_LICENSE, component: CreateProfessionLicense },
  { path: RouteName.VIEW_HASHTAGS, component: ViewHashTags },
  { path: RouteName.CREATE_HASHTAG, component: CreateHashTags },
  { path: RouteName.VIEW_THEME_IMAGE, component: ViewThemeImages },
  { path: RouteName.CREATE_THEME_IMAGE, component: CreateThemeImage },
  { path: RouteName.VIEW_ALL_CLIENTS, component: ViewAllClients },
  { path: RouteName.VIEW_ALL_THERAPISTS, component: ViewAllTherapists },
  { path: RouteName.VIEW_AVAILABLE_BALANCES, component: ViewAvailableBalances },
  { path: RouteName.ADMIN_APPROVE_PAYMENT, component: AdminApprovePayment },
  { path: RouteName.REFERRAL_EARNINGS, component: ReferralEarnings },
  { path: RouteName.CLIENT_REWARDS, component: ClientRewards },
  { path: RouteName.USER_PROFILE, component: ProfileDetails },
  { path: RouteName.REPORT_REVIEWS, component: ReportReviews },
  { path: RouteName.VIEW_PROFILE_BY_ADMIN, component: ViewUserProfile },
  { path: RouteName.VIEW_PUBLIC_PROFILE_BY_ADMIN, component: ViewUserPublicProfile },
  { path: RouteName.VIEW_CONTACT_US_REQUESTS, component: ViewContactUs },
  { path: RouteName.TECH_TICKETS, component: TechTickets },
  { path: RouteName.REVIEWS, component: ViewLavniReviews },
  { path: RouteName.VIEW_EDUCATIONAL_DETAILS, component: ViewEducationalDetails },
  { path: RouteName.VIEW_LICENSE_DETAILS, component: ViewLicenseDetails },
  { path: RouteName.VIEW_ARTICLES, component: ViewArticles },
  { path: RouteName.VIEW_ARTICLE, component: ViewArticleDetails },
  { path: RouteName.FEEDBACK, component: ViewFeedback },
  { path: RouteName.VIEW_INSURANCE_BY_ADMIN, component: ViewInsurance },
  { path: RouteName.VIEW_PREMIUM_CLIENTS, component: ManagePremiumClients },
  { path: RouteName.INSURANCE_EMAIL, component: ManageInvoices },
  { path: RouteName.SEND_MARKETING_EMAIL, component: MarketingEmail },
  { path: RouteName.VIEW_MARKETING_EMAILS, component: ViewMarketingEmails },
  { path: RouteName.VIEW_INSURANCE_COMPANIES, component: ViewInsuranceCompanies },
  { path: RouteName.CREATE_INSURANCE_COMPANY, component: CreateInsuranceCompany },
  { path: RouteName.CREATE_APPOINTMENT_ADMIN, component: AddAppoinmentAdmin },
  { path: RouteName.CLIENT_SESSIONS, component: ClientSessionsView },
  { path: RouteName.THERAPIST_SESSIONS, component: TherapistSessions },
  // { path: RouteName.DOCUMENTS, component: DocumentLayoutAdmin },
  { path: RouteName.DOCUMENTS, component: TherapistDocuments },
  { path: RouteName.THERAPIST_DOC_FOLDER, component: FolderComponent },
  { path: RouteName.VIEW_MEETINGS_AND_RECORDINGS, component: ViewMeetingsAndRecordings },
  { path: RouteName.VIEW_MEETINGS_AND_RECORDINGS_DEV, component: ViewMeetingsAndRecordingsDev },
  { path: RouteName.VIEWTHERAPISTREVIEW, component: ViewTherapistReview },
  { path: RouteName.VIEWTHERAPISTSSOAPREVIEW, component: ViewTherapistsSOAPReview },
  { path: RouteName.NEWDASHBOARD, component: AdminNewDashboard },
  { path: RouteName.NEWSLETTER_EMAILS, component: NewletterEmail },
  { path: RouteName.ADD_INSURANCE_BY_ADMIN, component: AdInsuranceByAdmin },
  { path: RouteName.View_THERAPIST_SCORE_HEIGHTS, component: EditTherapistScoreConstants },
  { path: RouteName.View_THERAPIST_SCORE, component: ViewTherapistScore },
  { path: RouteName.SESSION_FEEDBACK, component: ViewSessionFeedback },
  { path: RouteName.NOTIFICATIONS, component: AllNotifications },
  { path: RouteName.ADMIN_PROFILE, component: ViewAdminProfile },
  { path: RouteName.ACCESS_MANAGEMENT, component: DocumentAccessManagement },
  { path: RouteName.THERAPIST_REQUEST, component: TherapistRequests },
  { path: RouteName.APPROVAL_QUEUE, component: ApprovalQueue },
  { path: RouteName.DOC_DOWNLOAD, component: DocDownload },
  { path: RouteName.ADD_STATE, component: AddStates },
];

const saasRoutes = [
  {
    path: RouteName.ROOT,
    exact: true,
    component: () => <Redirect to={RouteName.DASHBOARD} />,
  },
  { path: RouteName.SAAS_ALL_CLIENTS, component: AllClients },
  { path: RouteName.SAAS_CLIENTS_CHART, component: ClientsChart },
  { path: RouteName.SAAS_TODO, component: ToDoSaas },
  { path: RouteName.SAAS_CLAIMS, component: ClaimsSaas },
  { path: RouteName.SAAS_CREDENTIALING, component: CredentiallingSaas },
  { path: RouteName.SAAS_PAYMENT, component: PaymentSaas },
  { path: RouteName.THERAPIST_REFERRAL_NEW, component: TherapistReferral },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/main", component: Main },
  { path: "/verify/:clientId", component: clientVerify },
  { path: "/public-articles-grid", component: PublicArticalIndex },
  { path: "/blogs", component: PublicArticles },
  { path: "/signin" },
  { path: "/home" },
  { path: "/home2" },
  { path: "/about" },
  { path: "/contact" },
  { path: "/service" },
  { path: "/pricing" },
  { path: "/signUp" },
  { path: "/forget-password" },
  { path: "/recover-password" },
  { path: "/terms" },
  { path: "/privacy" },
  { path: "/noshow-policy" },
  { path: "/faq" },
  { path: "/consent-document" },
  { path: "/articles/:articleId" },
  { path: "/audio-call" },
  { path: "/assesmentonboarding-page", component: AssesmentOnboardingPage },
  { path: "/therapyplan-page", component: TherapyPlanPage },
  { path: "/assesment-client-signature/:clientId", ClientSignaturePage },
  { path: "/therapy-plan-client-signature/:clientId", ClientTherapyPlanSignaturePage },
  // { path: RouteName.WELCOME, component: WelcomePage },
  { path: "/tomorrow-schedule/:scheduleId", TomorrowSchedulePageForTherapist },
  { path: "/digital-assesment-client-signature/:formId", DigitalClientAssessmentSignaturePage },
];

export { publicRoutes, clientRoutes, adminRoutes, therapistRoutes, saasRoutes };
