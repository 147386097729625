import PropTypes from "prop-types";
import React, { useEffect, useRef, useState, useContext } from "react";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { RouteName } from "../../RouteName";
import { NavLink } from "react-router-dom";
import FeedbackContext from "src/context/UserFeedbackCount";
import ContactContext from "src/context/UserContactRequest";
import TherapistRewardContext from "src/context/TherapistRewardCount";
import ClientRewardContext from "src/context/ClientRewardCount";
import ReviewContext from "src/context/ReviewContext";
import adminPagePermission from "src/helpers/permissions/admin-permission"
import TechTicketContext from "src/context/TechTicketRequest";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

const SidebarContent = props => {
  const ref = useRef();
  const [unreadFeedbackCount] = useContext(FeedbackContext);
  const [unreadContactCount] = useContext(ContactContext);
  const [unreadTherapistRewardCount] = useContext(TherapistRewardContext);
  const [unreadClientRewardCount] = useContext(ClientRewardContext);
  const [unreadReviewsCount] = useContext(ReviewContext);
  const { statistics, adminDashboard, new_admin_dashboard, viewEthnicity, viewExperienceTags, viewInsuranceCompanies, createAppointmentAdmin, viewProfessions, documents, accessManagement, viewHashTags,
    viewThemeImage, reportReviews, reviews, contactUs, articles, feedback, newsLetterEmails, marketingEmails, viewMeetingsAndRecordings, viewAllClients,
    manageClients, premiumClients, reminderSms, viewAllTherapists, manageTherapists, viewTherapistReviews, viewTherapistsSoapReviews,
    educationalDetails, licenseDetails, therapistRequests, availableBalances, adminApprovePayment, referralEarnings, clientRewards, notifications, sessionFeedback, approvalQueue, techTickets } = adminPagePermission();

  const [unreadTechTicketCount] = useContext(TechTicketContext);

  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");

      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
    };

    initMenu();
  }, []);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled mm-show mm-active" id="side-menu">
            {statistics && (
              <li>
                <NavLink to={RouteName.NEWDASHBOARD}>
                  <i className="bx bx-line-chart"></i>
                  <span>{props.t("Statistics")}</span>
                </NavLink>
              </li>
            )}
            {adminDashboard && (
              <li>
                <NavLink to={RouteName.ADMIN_DASHBOARD}>
                  <i className="bx bxs-dashboard"></i>
                  <span>{props.t("Dashboard")}</span>
                </NavLink>
              </li>
            )}
            {new_admin_dashboard && (
              <li>
                <NavLink to={RouteName.NEW_ADMIN_DASHBOARD}>
                  <i className="bx bxs-dashboard"></i>
                  <span>{props.t("New Admin Dashboard")}</span>
                </NavLink>
              </li>
            )}
            {viewEthnicity && (
              <li>
                <NavLink to={RouteName.VIEW_ETHNICITY}>
                  <i className="bx bx-store"></i>
                  <span>{props.t("View Ethnicity Tags")}</span>
                </NavLink>
              </li>
            )}

            {viewExperienceTags && (
              <li>
                <NavLink to={RouteName.VIEW_EXPERIENCE_TAGS}>
                  <i className="bx bxs-purchase-tag"></i>
                  <span>{props.t("View Experience Tags")}</span>
                </NavLink>
              </li>
            )}

            {viewInsuranceCompanies && (
              <li>
                <NavLink to={RouteName.VIEW_INSURANCE_COMPANIES}>
                  <i className="bx bxs-home"></i>
                  <span>{props.t("Insurance Companies")}</span>
                </NavLink>
              </li>
            )}

            {createAppointmentAdmin && (
              <li>
                <NavLink to={RouteName.CREATE_APPOINTMENT_ADMIN}>
                  <i className="fa fa-calendar"></i>
                  <span>{props.t("Add Appointments")}</span>
                </NavLink>
              </li>
            )}

            {viewProfessions && (
              <li>
                <NavLink to={RouteName.VIEW_PROFESSIONS} className="">
                  <i className="bx bxs-user-pin"></i>
                  <span>{props.t("View Professions")}</span>
                </NavLink>
              </li>
            )}

            {(documents || accessManagement) && (
              <li>
                <NavLink to="/documents" className="has-arrow">
                  <i className="bx bx-book-content"></i>
                  <span>{props.t("Training Documents")}</span>
                </NavLink>

                <ul className="sub-menu" aria-expanded="false">
                  {documents && (
                    <li>
                      <NavLink to={RouteName.DOCUMENTS}>{props.t("Documents")}</NavLink>
                    </li>
                  )}

                  {accessManagement && (
                    <li>
                      <NavLink to={RouteName.ACCESS_MANAGEMENT}>
                        <span>{props.t("Document Access")}</span>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </li>
            )}

            {viewHashTags && (
              <li>
                <NavLink to={RouteName.VIEW_HASHTAGS} className="">
                  <i className="bx bx-hash"></i>
                  <span>{props.t("View Hash Tags")}</span>
                </NavLink>
              </li>
            )}

            {viewThemeImage && (
              <li>
                <NavLink to={RouteName.VIEW_THEME_IMAGE} className="">
                  <i className="bx bxs-image-add"></i>
                  <span>{props.t("View Theme Images")}</span>
                </NavLink>
              </li>
            )}

            {reportReviews && (
              <li>
                <NavLink to={RouteName.REPORT_REVIEWS} className="">
                  <i className="bx bx-group"></i>
                  <span>{props.t("Report Reviews")}</span>
                </NavLink>
              </li>
            )}

            {reviews && (
              <li>
                <NavLink to={RouteName.REVIEWS} className="flex">
                  <i className="bx bx-group"></i>
                  <span>{props.t("Customer Reviews")}</span>
                  {unreadReviewsCount == 0 ? "" : <div className="bubble">{unreadReviewsCount}</div>}
                </NavLink>
              </li>
            )}

            {contactUs && (
              <li>
                <NavLink to={RouteName.VIEW_CONTACT_US_REQUESTS} className="flex">
                  <i className="bx bx-group"></i>
                  <span>{props.t("Contact Requests")}</span>
                  {unreadContactCount == 0 ? "" : <div className="bubble">{unreadContactCount}</div>}
                </NavLink>
              </li>
            )}

            {techTickets && (
              <li>
                <NavLink to={RouteName.TECH_TICKETS} className="flex">
                  <i className="bx bx-group"></i>
                  <span>{props.t("Tech Tickets")}</span>
                  {unreadTechTicketCount == 0 ? "" : <div className="bubble">{unreadTechTicketCount}</div>}
                </NavLink>
              </li>
            )}

            {articles && (
              <li>
                <NavLink to={RouteName.VIEW_ARTICLES} className="">
                  <i className="bx bx-book-content"></i>
                  <span>{props.t("Articles")}</span>
                </NavLink>
              </li>
            )}

            {feedback && (
              <li>
                <NavLink to={RouteName.FEEDBACK} className="flex">
                  <i className="bx bxs-user-detail"></i>
                  <span>{props.t("Customer Feedback")} </span>
                  {unreadFeedbackCount == 0 ? "" : <div className="bubble">{unreadFeedbackCount}</div>}
                </NavLink>
              </li>
            )}

            {newsLetterEmails && (
              <li>
                <NavLink to={RouteName.NEWSLETTER_EMAILS}>
                  <i className="bx bx-envelope"></i>
                  <span>{props.t("Newsletter Emails")}</span>
                </NavLink>
              </li>
            )}

            {marketingEmails && (
              <li>
                <NavLink to={RouteName.VIEW_MARKETING_EMAILS} className="">
                  <i className="bx bx-envelope"></i>
                  <span>{props.t("Marketing Email")}</span>
                </NavLink>
              </li>
            )}

            {viewMeetingsAndRecordings && (
              <li>
                <NavLink to={RouteName.VIEW_MEETINGS_AND_RECORDINGS} className="">
                  <i className="bx bx-envelope"></i>
                  <span>{props.t("Meetings & Recordings")}</span>
                </NavLink>
              </li>
            )}

            {(viewAllClients || manageClients || premiumClients || reminderSms) && (
              <li>
                <NavLink to="/client" className="has-arrow">
                  <i className="bx bxs-user"></i>
                  <span>{props.t("Client")}</span>
                </NavLink>

                <ul className="sub-menu" aria-expanded="false">
                  {viewAllClients && (
                    <li>
                      <NavLink to={RouteName.VIEW_ALL_CLIENTS}>{props.t("View Clients")}</NavLink>
                    </li>
                  )}

                  {manageClients && (
                    <li>
                      <NavLink to={RouteName.MANAGE_CLIENTS} className=" ">
                        <span>{props.t("Manage Clients")}</span>
                      </NavLink>
                    </li>
                  )}

                  {premiumClients && (
                    <li>
                      <NavLink to={RouteName.VIEW_PREMIUM_CLIENTS} className=" ">
                        <span>{props.t("Premium Clients")}</span>
                      </NavLink>
                    </li>
                  )}

                  {/* <li>
                  <NavLink to={RouteName.REMINDER_SMS} className=" ">
                    <span>{props.t("Reminder SMS")}</span>
                  </NavLink>
                </li> */}
                </ul>
              </li>
            )}

            {(viewAllTherapists ||
              manageTherapists ||
              viewTherapistReviews ||
              viewTherapistsSoapReviews ||
              educationalDetails ||
              licenseDetails ||
              therapistRequests) && (
              <li>
                <NavLink to="/therapist" className="has-arrow">
                  <i className="bx bx-user"></i>
                  <span>{props.t("Therapist")}</span>
                </NavLink>

                <ul className="sub-menu" aria-expanded="false">
                  {viewAllTherapists && (
                    <li>
                      <NavLink to={RouteName.VIEW_ALL_THERAPISTS}>{props.t("View Therapists")}</NavLink>
                    </li>
                  )}

                  {manageTherapists && (
                    <li>
                      <NavLink to={RouteName.MANAGE_THERAPISTS}>
                        <span>{props.t("Manage Therapists")}</span>
                      </NavLink>
                    </li>
                  )}

                  {viewTherapistReviews && (
                    <li>
                      <NavLink to={RouteName.VIEWTHERAPISTREVIEW} className=" ">
                        <span>{props.t("Manage Reviews")}</span>
                      </NavLink>
                    </li>
                  )}

                  {viewTherapistsSoapReviews && (
                    <li>
                      <NavLink to={RouteName.VIEWTHERAPISTSSOAPREVIEW} className=" ">
                        <span>{props.t("AI SOAP Reviews")}</span>
                      </NavLink>
                    </li>
                  )}

                  {educationalDetails && (
                    <li>
                      <NavLink to={RouteName.VIEW_EDUCATIONAL_DETAILS}>
                        <span>{props.t("Educational Details")}</span>
                      </NavLink>
                    </li>
                  )}

                  {licenseDetails && (
                    <li>
                      <NavLink to={RouteName.VIEW_LICENSE_DETAILS}>
                        <span>{props.t("Licenses / Permits")}</span>
                      </NavLink>
                    </li>
                  )}

                  {therapistRequests && (
                    <li>
                      <NavLink to={RouteName.THERAPIST_REQUEST}>
                        <span>{props.t("Therapist Requests")}</span>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </li>
            )}

            {(availableBalances || adminApprovePayment || referralEarnings || clientRewards) && (
              <li>
                <NavLink to="/payment" className="has-arrow">
                  <i className="bx bx-credit-card"></i>
                  <span>{props.t("Payment")}</span>
                </NavLink>

                <ul className="sub-menu" aria-expanded="false">
                  {availableBalances && (
                    <li>
                      <NavLink to={RouteName.VIEW_AVAILABLE_BALANCES}>{props.t("View Available Balances")}</NavLink>
                    </li>
                  )}

                  {adminApprovePayment && (
                    <li>
                      <NavLink to={RouteName.ADMIN_APPROVE_PAYMENT}>
                        <span>{props.t("Admin Approval")}</span>
                      </NavLink>
                    </li>
                  )}

                  {referralEarnings && (
                    <li>
                      <NavLink to={RouteName.REFERRAL_EARNINGS} className="flex">
                        <span>{props.t("Referral Earnings")}</span>
                        {unreadTherapistRewardCount == 0 ? "" : <div className="bubble">{unreadTherapistRewardCount}</div>}
                      </NavLink>
                    </li>
                  )}

                  {clientRewards && (
                    <li>
                      <NavLink to={RouteName.CLIENT_REWARDS} className="flex">
                        <span>{props.t("Client Rewards")}</span>
                        {unreadClientRewardCount == 0 ? "" : <div className="bubble">{unreadClientRewardCount}</div>}
                      </NavLink>
                    </li>
                  )}
                </ul>
              </li>
            )}

            <li>
              <NavLink to="/therapist-score" className="has-arrow">
                <i className="bx bx-credit-card"></i>
                <span>{props.t("Therapist Score")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to={RouteName.View_THERAPIST_SCORE_HEIGHTS}>
                    <span>{props.t("Therapist Score Heights")}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={RouteName.View_THERAPIST_SCORE}>
                    <span>{props.t("View Therapist Score")}</span>
                  </NavLink>
                </li>
              </ul>
            </li>

            {sessionFeedback && (
              <li>
                <NavLink to={RouteName.SESSION_FEEDBACK} className="flex">
                  <i className="bx bxs-user-detail"></i>
                  <span>{props.t("Session Feedback")} </span>
                </NavLink>
              </li>
            )}

            {approvalQueue && (
              <li>
                <NavLink to="/insurance" className="has-arrow">
                  <i className="bx bx-book-content"></i>
                  <span>{props.t("Insurance Documents")}</span>
                </NavLink>

                <ul className="sub-menu" aria-expanded="false">
                  {approvalQueue && (
                    <li>
                      <NavLink to={RouteName.APPROVAL_QUEUE}>
                        <span>{props.t("Approval Queue")}</span>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </li>
            )}

            <li>
              <NavLink to={RouteName.DOC_DOWNLOAD} className="">
                <i className="bx bx-book-content"></i>
                <span>{props.t("Document Downloads")}</span>
              </NavLink>
            </li>

            {notifications && (
              <li>
                <NavLink to={RouteName.NOTIFICATIONS} className="">
                  <i className="bx bx-bell"></i>
                  <span>{props.t("Notifications")}</span>
                </NavLink>
              </li>
            )}

            <li>
              <NavLink to={RouteName.ADD_STATE} className="">
                <i className="bx bx-bell"></i>
                <span>{props.t("Add State")}</span>
              </NavLink>
            </li>


          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));