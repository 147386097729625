export enum RouteName {
  ROOT = "/",
  DASHBOARD = "/dashboard",
  DASHBOARD_NEW = "/dashboard-client",
  DASHBOARD_NEW2 = "/dashboard-therapist",
  ADMIN_DASHBOARD = "/admin-dashboard",
  NEW_ADMIN_DASHBOARD = "/new-admin-dashboard",
  PROFILE = "/profile",
  PROFILE_COPAYMENT = "/profile/:activeTab",
  REQUEST = "/requests",
  INSURANCE = "/insurance",
  ADD_INSURANCE = "/add-insurance",
  ADD_INSURANCE_BY_ADMIN = "/add-insurance-by-admin/:userId",
  PROFILE_VIEW = "/profile-view/:userId",
  CHAT = "/chat/:chatId?",
  CHAT_MENU="/chat",
  ARTICLE_LIST = "/articles",
  ARTICLES_GRID = "/articles-grid",
  CREATE_ARTICLES = "/create-article",
  ARTICLE_DETAILS = "/article-details/:articleId/:viewtype",
  ARTICLE_DETAIL = "/article-details/:articleId/",
  CREATE_ARTICLE = "/create-article",
  APPOINTMENTS = "/appointments",
  CLIENT_PROFILE = "/client-profile/:userProfileId",
  USER_PROFILE = "/profile/details/:userProfileId",
  MEETINGS = "/meetings",
  ONBOARDING = "/onboarding-page",
  MANAGE_CLIENTS = "/manage-clients",
  MANAGE_THERAPISTS = "/manage-therapists",
  VIEW_ETHNICITY = "/view-ethnicity",
  CREATE_ETHNICITY = "/create-ethnicity",
  VIEW_EXPERIENCE_TAGS = "/view-experience-tags",
  CREATE_EXPERIENCE_TAGS = "/create-experience-tags",
  VIEW_PROFESSIONS = "/view-professions",
  CREATE_PROFESSION = "/create-profession",
  UPDATE_PROFESSION = "/update-profession/:professionId",
  VIEW_PROFESSION_LICENSE = "/view-profession-license/:professionId",
  CREATE_PROFESSION_LICENSE = "/create-profession-license/:professionId",
  VIEW_HASHTAGS = "/view-hash-tags",
  CREATE_HASHTAG = "/create-hash-tag",
  VIEW_THEME_IMAGE = "/view-theme-image",
  CREATE_THEME_IMAGE = "/create-theme-image",
  VIEW_ALL_CLIENTS = "/view-all-clients",
  VIEW_ALL_THERAPISTS = "/view-all-therapists",
  CREATE_APPOINTMET = "/create-appointment/:userId",
  NOTIFICATIONS = "/notifications",
  SUBSCRPTION = "/subscription",
  CHOOSE_PLAN = "/choose-plan",
  WELCOME = "/welcome-page",
  ADD_PAYMENT = "/add-payment",
  EARNINGS = "/earnings/:stripeConnected?",
  EARNINGS_MENU = "/earnings",
  REPORT_REVIEWS = "/report-reviews",
  VIEW_PROFILE_BY_ADMIN = "/profile/:userId",
  VIEW_PUBLIC_PROFILE_BY_ADMIN = "/public-profile/:userId",
  VIEW_CONTACT_US_REQUESTS = "/contact-us",
  TECH_TICKETS = "/tech-tickets",
  REVIEWS = "/reviews",
  VIEW_EDUCATIONAL_DETAILS = "/educational-details",
  VIEW_LICENSE_DETAILS = "/license-details",
  VIEW_ARTICLES = "/articles",
  VIEW_ARTICLE = "/article/:articleId",
  SUPPORT = "/support",
  FEEDBACK = "/feedback",
  FEEDBACK1 = "/feedback1",
  VIEW_INSURANCE_BY_ADMIN = "/insurance/:userId/:activeTab?",
  VIEW_PREMIUM_CLIENTS = "/premium-clients",
  // REMINDER_SMS = "/reminder-sms",
  // SINGLE_CLIENT_REMINDER_SMS = "/client-reminder-sms/:clientId?",
  INSURANCE_EMAIL = "/mail/clients/:userId",
  VIEW_PAYMENT_HISTORY = "/payment-history",
  VIEW_MARKETING_EMAILS = "/marketing-emails",
  SEND_MARKETING_EMAIL = "/send-marketing-email",
  NOTES = "/notes/:userId",
  DOCUMENTS = "/documents",
  VIEW_INSURANCE_COMPANIES = "/view-insurance-companies",
  CREATE_INSURANCE_COMPANY = "/create-insurance-company",
  CREATE_APPOINTMENT_ADMIN = "/create-appointment-admin",
  CLIENT_SESSIONS = "/client-sessions/:userId",
  THERAPIST_SESSIONS = "/therapist-sessions/:userId",
  VIEW_MEETINGS_AND_RECORDINGS = "/view-meetings-and-recordings",
  VIEW_MEETINGS_AND_RECORDINGS_DEV = "/view-meetings-and-recordings-dev",
  MEETING_SCREEN_NEW = "/meeting-screen",
  VIEW_AVAILABLE_BALANCES = "/available-balances",
  ADMIN_APPROVE_PAYMENT = "/admin-approve-payment",
  REFERRAL_EARNINGS = "/referral-earnings",
  CLIENT_REWARDS = "/client-rewards",
  LAVNI_SESSION_ROOM = "/lavni-session/:meetingId?",
  LAVNI_AVATAR = "/lavni-avatar",
  VIEWTHERAPISTREVIEW = "/view-therapist-reviews",
  VIEWTHERAPISTSSOAPREVIEW = "/view-therapists-soap-reviews",
  NEWDASHBOARD = "/statistics",
  ADD_SESION = "/add-session",
  MAIN_THERAPIST = "/public-appoinments/:therapistProfileId",
  AUDIO_CALL = "/audio-call",
  PAGENOTFOUND = "/*",
  NEWSLETTER_EMAILS = "/newsletter-emails",
  THERAPIST_REFERRAL = "/therapist-referral/:userId",
  CLIENT_REFERRAL = "/client-referral/:userId",
  VONAGE_SESSION = "/vonage-session",
  VONAGE_EXPRESS = "/room/:roomName",
  VONAGE_VIDEO_ROOM = "/video-room/:roomName",
  DIAGNOSIS_NOTE_SCREEN = "/diagnosis-note-screen",
  VONAGE_END_CALL = "/video-room/:roomName/:sessionId/end",
  VONAGE_EXPRESS_GROUP = "/room-group/:roomName",
  VONAGE_VIDEO_ROOM_GROUP = "/video-room-group/:roomName",
  VONAGE_END_CALL_GROUP = "/video-room-group/:roomName/:sessionId/end",
  VONAGE_CALL = "/vonage-call",
  VONAGE_VIDEOROOM = "/room/video-room",
  GROUP_CHAT = "/group-chat/:groupChatId?",
  GROUP_CHAT_MENU = "/group-chat",
  PAYMENT_HISTORY_CARD = "/payment-history/:activeTab",
  PUBLIC_GROUP_CHAT = "/public-group-chat",
  TECH = "/tech",
  View_THERAPIST_SCORE_HEIGHTS = "/view-therapist-score-heights",
  View_THERAPIST_SCORE = "/view-therapist-score",
  SESSION_FEEDBACK = "/session-feedback",
  ADMIN_PROFILE = "/profile",
  THERAPIST_TRAINING_DOCS = "/training-documents",
  THERAPIST_DOC_FOLDER = "/documents/folder/:id",
  ACCESS_MANAGEMENT = "/access-management",
  THERAPIST_REQUEST = "/therapist-requests",
  THERAPISTPERSONALINFORMATIONONBOARDINGPAGE = "/personal-information-onboarding-page",
  THERAPISTPROFESSIONALEXPERIENCEONBOARDINGPAGE = "/professional-experience-onboarding-page",
  THERAPISTWORKINGDAYSONBOARDINGPAGE = "/working-days-onboarding-page",
  THERAPISTINTRODUCTORYVIDEOONBOARDINGPAGE = "/introductory-video-onboarding-page",
  APPROVAL_QUEUE = "/approval-queue",
  APPROVALS = "/approvals",
  DOC_DOWNLOAD = "/doc-download",
  SHOW_THERAPIST_SCORE = '/show-therapist-score',
  VONAGE_NATIVE_SDK_WAITING_ROOM = '/session/waiting-room/:uniqueId/:isMeetStartedPerson',
  VONAGE_NATIVE_SDK_VIDEO_ROOM = '/session/video-room/:uniqueId',
  SAAS_ALL_CLIENTS = "/clients",
  SAAS_CLIENTS_CHART = "/clients-chart",
  SAAS_TODO = "/todo",
  SAAS_VIEW_PROFILE= "/saas-view-profile/:userId",
  SAAS_CLAIMS = "/saas-claims",
  SAAS_CREDENTIALING = "/saas-credentialing",
  SAAS_PAYMENT = "/saas-payment",
  THERAPIST_REFERRAL_NEW = "/therapist-referral/",
  ADD_STATE = "/add-state"
}
