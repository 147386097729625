import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../context/UserContext";
import { UserRole } from "src/models/User";

const adminPagePermission = () => {
  const [user] = useContext(UserContext);

  let statistics = true;
  let adminDashboard = true;
  let new_admin_dashboard = true;
  let viewEthnicity = true;
  let viewExperienceTags = true;
  let viewInsuranceCompanies = true;
  let createAppointmentAdmin = true;
  let viewProfessions = true;
  let documents = true;
  let accessManagement = true;
  let viewHashTags = true;
  let viewThemeImage = true;
  let reportReviews = true;
  let reviews = true;
  let contactUs = true;
  let articles = true;
  let feedback = true;
  let newsLetterEmails = true;
  let marketingEmails = true;
  let viewMeetingsAndRecordings = true;
  let viewAllClients = true;
  let manageClients = true;
  let premiumClients = true;
  let reminderSms = true;
  let viewAllTherapists = true;
  let manageTherapists = true;
  let viewTherapistReviews = true;
  let viewTherapistsSoapReviews = true;
  let educationalDetails = true;
  let licenseDetails = true;
  let therapistRequests = true;
  let availableBalances = true;
  let adminApprovePayment = true;
  let referralEarnings = true;
  let clientRewards = true;
  let notifications = true;
  let sessionFeedback = true;
  let approvalQueue = true;
  let techTickets = true;

  if (user.role != UserRole.SUPER_ADMIN || user.adminPermission) {
    statistics = user.adminPermission.statistics;
    adminDashboard = user.adminPermission.adminDashboard;
    new_admin_dashboard = user.adminPermission.new_admin_dashboard;
    viewEthnicity = user.adminPermission.viewEthnicity;
    viewExperienceTags = user.adminPermission.viewExperienceTags;
    viewInsuranceCompanies = user.adminPermission.viewInsuranceCompanies;
    createAppointmentAdmin = user.adminPermission.createAppointmentAdmin;
    viewProfessions = user.adminPermission.viewProfessions;
    documents = user.adminPermission.documents;
    accessManagement = user.adminPermission.accessManagement;
    viewHashTags = user.adminPermission.viewHashTags;
    viewThemeImage = user.adminPermission.viewThemeImage;
    reportReviews = user.adminPermission.reportReviews;
    reviews = user.adminPermission.reviews;
    contactUs = user.adminPermission.contactUs;
    articles = user.adminPermission.articles;
    feedback = user.adminPermission.feedback;
    newsLetterEmails = user.adminPermission.newsLetterEmails;
    marketingEmails = user.adminPermission.marketingEmails;
    viewMeetingsAndRecordings = user.adminPermission.viewMeetingsAndRecordings;
    viewAllClients = user.adminPermission.viewAllClients;
    manageClients = user.adminPermission.manageClients;
    premiumClients = user.adminPermission.premiumClients;
    reminderSms = user.adminPermission.reminderSms;
    viewAllTherapists = user.adminPermission.viewAllTherapists;
    manageTherapists = user.adminPermission.manageTherapists;
    viewTherapistReviews = user.adminPermission.viewTherapistReviews;
    viewTherapistsSoapReviews = user.adminPermission.viewTherapistsSoapReviews;
    educationalDetails = user.adminPermission.educationalDetails;
    licenseDetails = user.adminPermission.licenseDetails;
    therapistRequests = user.adminPermission.therapistRequests;
    availableBalances = user.adminPermission.availableBalances;
    adminApprovePayment = user.adminPermission.adminApprovePayment;
    referralEarnings = user.adminPermission.referralEarnings;
    clientRewards = user.adminPermission.clientRewards;
    notifications = user.adminPermission.notifications;
    sessionFeedback = user.adminPermission.sessionFeedback;
    approvalQueue = user.adminPermission.approvalQueue;
    techTickets = user.adminPermission.techTickets;
  }

  return {
    statistics,
    adminDashboard,
    new_admin_dashboard,
    viewEthnicity,
    viewExperienceTags,
    viewInsuranceCompanies,
    createAppointmentAdmin,
    viewProfessions,
    documents,
    accessManagement,
    viewHashTags,
    viewThemeImage,
    reportReviews,
    reviews,
    contactUs,
    articles,
    feedback,
    newsLetterEmails,
    marketingEmails,
    viewMeetingsAndRecordings,
    viewAllClients,
    manageClients,
    premiumClients,
    reminderSms,
    viewAllTherapists,
    manageTherapists,
    viewTherapistReviews,
    viewTherapistsSoapReviews,
    educationalDetails,
    licenseDetails,
    therapistRequests,
    availableBalances,
    adminApprovePayment,
    referralEarnings,
    clientRewards,
    notifications,
    sessionFeedback,
    approvalQueue,
    techTickets,
  };
};
export default adminPagePermission;
